<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-30'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					Подробное описание товара (работы, услуги)
				</AppText>
			</div>
			<div class="section__top-right"></div>
		</div>

		<div class="section__body" :class="isMobileSmall ? 'mb-30' : 'mb-60'">
			<div class="table-block secondary-table mb-20">
				<table>
					<thead>
						<tr>
							<th style="max-width: 300px;">№</th>
							<th style="max-width: 300px;">Наименование</th>
							<th style="max-width: 300px;">{{$t('amountShort')}}</th>
							<th style="max-width: 300px;">ед.Изм.</th>
							<th style="max-width: 300px;">Цена единицы</th>
							<th style="max-width: 300px;">Срок поставки (исполнения)</th>
							<th style="max-width: 300px;">Производитель</th>
							<th width="180">Страна происхождения</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style="max-width: 300px; background: #F3F8FC">
								<AppText weight="600" size="14">1</AppText>
							</td>
							<td style="max-width: 300px; background: #F3F8FC">
								<AppText weight="600" size="14">Сыр</AppText>
							</td>

							<td style="max-width: 300px;">4</td>
							<td style="max-width: 300px;">Кг</td>
							<td style="max-width: 300px;">
								<AppText weight="600" size="14">20 000 UZS</AppText>
							</td>
							<td>
								9/25/21
							</td>
							<td>
								OOO “Chashma”
							</td>
							<td>
								Узбекистан
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="d-flex">
				<AppText weight="400" size="14">Источник финансирования: </AppText>
				<AppText class="ml-10" weight="600" size="14">Бюджет</AppText>
			</div>

			<div>
				<app-content-card class="custom-tab-component" padding-x="0" padding-y="0" :radius="[8, 8, 0, 0]">
					<app-tabs v-model="selectedTab" :list="tabItems" />
				</app-content-card>

				<template v-if="selectedTab === 'directContracts'">
					<div class="mt-20">
				
					</div>
				</template>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "@/components/shared-components/AppText";
import AppContentCard from "@/components/shared-components/AppContentCard";
import AppTabs from "@/components/shared-components/AppTabs";

export default {
	name: "index",
	components: {
		AppText,
		AppContentCard,
		AppTabs
	},

	data() {
		return {
			selectedTab: "directContracts",
			tabItems: [
				{
					name: "Изменение договора",
					value: "directContracts"
				},
				{
					name: "Исполнение договора",
					value: "auction"
				},
				{
					name: "Расторжение договора",
					value: "shop"
				}
			]
		};
	}
};
</script>

<style lang="scss" scoped>
.secondary-table {
	text-align: center;
	background: #fafdff;

	th {
		background: var(--color-secondary);
		color: white;
		text-align: center;
		padding: 22px;
		border-right: 1px solid #684687;
	}
}
</style>
